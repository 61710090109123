<div class="wrapper-blur" [ngClass]="{'open-blur': isContactOpen}">
  <div class="header">
  <div class="container-header">
    <img class="title-glorious" routerLink="" src="../../../assets/glorious-light.svg" alt="logo" width="198px" height="47px">
      <div class="menu-buttons">
          <button class="button-contact" routerLink="/catalog">КАТАЛОГ</button>
      </div>
  </div>
</div>
<div class="block-blog">
  <div class="container-blog">
    <h1 class="title-blog">блог</h1>
    <ul class="list-blog">
      <li *ngFor="let post of posts" [routerLink]="['/blog', post.id]" class="item-blog">
        <img class="image-blog" [src]="post.image" alt="">
        <div class="container-blog-text">
          <h2 class="title-container-text-blog">{{ post.title }}</h2>
          <p class="text-container-blog">{{ post.text }}</p>
          <div class="container-blog-arrow">
            <p class="title-container-text-blog-arrow">{{ post.linkText }}</p>
            <img class="image-blog-arrow" src="../../../assets/arrow-up-right.svg" alt="">
          </div>
        </div>
      </li>
    </ul>    
  </div>
</div>
</div>