<div [ngClass]="{'open-blur': isContactOpen}">
  <div class="header">
    <div class="container-header">
      <img class="title-glorious" routerLink="" src="../../../assets/glorious-light.svg" alt="logo" width="198px" height="47px">
        <div class="menu-buttons">
            <button class="button-contact" routerLink="/catalog">КАТАЛОГ</button>
        </div>
    </div>
  </div>
  <div *ngIf="blogPost" class="blog-info">
    <div class="container">
        <img routerLink="/blog" class="image-blog-info-arrow" src="../../../assets/arrow-up-right.svg" alt="">
        <div class="container-blog-info" >
          <h1 class="title-blog-info">{{ blogPost.title }}</h1>
          <img class="image-blog-info" [src]="blogPost.image_info" alt="">
        </div>
          <p class="text-blog-info" [innerHTML]="blogPost?.text_info || ''"></p>
        </div>
  </div>
</div>