<div class="container-login">
  <div class="header">
    <div>
        <p class="title-glorious" routerLink="/">GLORIOUS</p>
    </div>
  </div>
  <div class="login-container">
    <div class="login-form">
      <h2 class="title">ВХІД В АДМІН ПАНЕЛЬ</h2>
      <form #loginForm="ngForm" class="form" (ngSubmit)="login(loginForm)">
        <div class="form-group">
            <input class="input" type="email" id="email" [(ngModel)]="email" name="email" placeholder="Пошта" required />
          </div>
          <div class="form-group">
            <input class="input" type="password" id="password" [(ngModel)]="password" name="password" placeholder="Пароль" required />
          </div>
          <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
        <button class="button-login" type="submit">ВХІД</button>
      </form>
    </div>
  </div>
</div>

