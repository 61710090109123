<div class="header">
  <div class="container-header">
    <img
    [routerLink]="['/catalog-use']"
      class="logo-glorious"
      src="../../../../assets/glorious-light.svg"
      alt="Logo"
      draggable="false"
    />
  </div>
</div>
<div class="main">
  <div class="container-main">
    <p class="text-main" [routerLink]="['/catalog-use']">Каталог/<span class="text-main-span">{{products[0]?.category?.label}}</span></p>
    @if (products.length > 0) {
    <h1 class="title-main">{{products[0]?.category?.label}}</h1>
    <div class="container-catalog">
      <ul class="list-catalog">
        @for (product of products; track $index;) {
        <li class="item-catalog">
          <img
            class="image-catalog"
            [src]="product.images[0]?.thumbnailUrl"
            [alt]="product.name || 'No Title Available'"
          />
        </li>
        } @if (products.length === 0) {
        <p class="text-catalog">Немає товарів у категорії</p>
        }
      </ul>
    </div>
    }
  </div>
</div>
<div class="footer">
  <div class="container-footer">
    <img
    [routerLink]="['/catalog-use']"
      class="logo-glorious-footer"
      src="../../../../assets/glorious-light.svg"
      alt="Logo"
      draggable="false"
    />
  </div>
  <p class="text-footer-bottom">Всі права захищені All rights reserved</p>
</div>
