<div class="error-page">
  <div class="header">
    <img class="title-glorious" routerLink="" src="../../../assets/glorious-light.svg" alt="logo" width="198px" height="47px">
  </div>
  <div class="container-error">
    <p class="title-error">404</p>
    <p class="text-error">Цієї сторінки не існує</p>
    <button class="button-error" routerLink="/">НА ГОЛОВНУ</button>
  </div>
</div>
